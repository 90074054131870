angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.contacts', {
            url: '/configuration/contacts',
            views: {
                "main": {
                    controller: 'ContactsCtrl',
                    templateUrl: 'web/main/contacts/contacts.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                grupos: function (contactService) {
                    return contactService.getContactosGrupos();
                },
                contacts: function (contactService) {
                    return contactService.getContactoss();
                }
            },

            data: {
                pageTitle: 'Contacts'
            }
        });
    })

    .controller('ContactsCtrl', function ($scope, swalService, contactService, $translate, grupos, contacts, createContactModal, createContactGroupModal, $q) {
        var that = this;

        that.grupos = grupos.data;
        that.contacts = contacts.data;
        that.grupoSelected = null;
        var promises = [contactService.getContactoss(), contactService.getContactosGrupos()];
        $q.all(promises).then(function (responses) {
            that.contacts = responses[0].data;
            that.groups = responses[1].data;
            that.groups.unshift({
                groupName: $translate.instant('Todos'),
                groupUsers: that.contacts
            });
            that.groupSelected = that.groups[0];
            that.loaded = true;
        });

        that.filter = c =>(!that.grupoSelected || (that.contactGroups[c.id] && that.contactGroups[c.id][that.grupoSelected.id]));

        that.contactGroups = [];
        that.grupos.map(g=>g.groupUsers.map(c=>{
            that.contactGroups[c.id] = that.contactGroups[c.id] || [];
            that.contactGroups[c.id][g.id]=true;
        }));
        $scope.grupoSeleccionado = null;
        that.update = () => {
            contactService.getContactoss().then(response => that.contacts = response.data);
            contactService.getContactosGrupos().then(response=>{
                that.grupos = response.data;
                that.contactGroups = [];
                that.grupos.map(g=>g.groupUsers.map(c=>{
                    that.contactGroups[c.id] = that.contactGroups[c.id] || [];
                    that.contactGroups[c.id][g.id]=true;
                }));
            });
            
        };

        that.deleteContact = contact => swalService.requestSwal('¿Estás seguro?', 'Se va a borrar este contacto, esta acción no se puede deshacer', 'warning', () => contactService.deleteContact(contact.id))
            .then(() => {
                swal($translate.instant('¡Correcto!'), $translate.instant('Se ha eliminado el contacto'), 'success');
                that.update();
            });

        that.anyadirContacto = () => createContactModal.openModal().result.then(that.update);

        that.anyadirGrupo = function () {
            createContactGroupModal.openModal().result.then(function (r) {
                if (r) {
                    contactService.getContactosGrupos().then(response=>that.grupos = response.data);
                }
            });
        };

        that.deleteGrupo = grupo => swalService.requestSwal('¿Estás seguro?', 'Se va a borrar este grupo, esta acción no se puede deshacer', 'warning', () => contactService.deleteContactoGrupo(grupo.id))
            .then(()=>{
                swal($translate.instant('¡Correcto!'), $translate.instant('Se ha eliminado el grupo'), 'success');
                contactService.getContactosGrupos().then(response=>that.grupos = response.data);
            });

        that.editarGrupo =  (grupo) => $state.go('main.configuration_edit_contacts_groups', {id: grupo.id});

        that.addAll = function(){
            for (var j = 0; j < that.groupSelected.groupUsers.length; j++) {
                if(!that.groupSelected.groupUsers[j].checked){
                    that.groupSelected.groupUsers[j].checked = true;
                    that.change(that.groupSelected.groupUsers[j]);
                }
            }
        };
        that.change = function (contact) {
            if (!contact.checked) {
                for (var j = 0; j < that.contactsSelected.length; j++) {
                    if (that.contactsSelected[j].id == contact.id) {
                        that.contactsSelected.splice(j, 1);
                        contact.checked = false;
                        return;
                    }
                    contact.checked = false;
                }
            } else {
                that.contactsSelected.push(contact);
                contact.checked = true;
            }
        };
    
    });
